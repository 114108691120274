<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo d-flex align-items-center animate__animated animate__fadeInLeft">
        <aiot-logo />
        <span class="brand-text text-white text-center font-medium-5">
          {{ $t('name') }}
        </span>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password"
            style="max-height: 760px; max-width: 780px;"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5 animate__animated animate__fadeInRight"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title title-tag="h1" class="x-text-bold">
            {{ $t('forgotPassword') }}
          </b-card-title>
          <b-alert
            variant="primary"
            show
          >
            <div class="alert-body font-small-2">
              <p>
                <small class="mr-50">{{ $t('forgotPasswordTooltip') }}</small>
              </p>
            </div>
          </b-alert>
          <!-- form -->
          <validation-observer ref="simpleRules" #default="{invalid}">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group
                :label="$t('email')"
                label-class="h4"
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-email"
                    :placeholder="$t('placeholderEmail')"
                  />
                  <small class="text-danger" v-if="errors[0]">{{ $t('forgotPasswordTooltip') }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                {{ $t('sendResetPwdLink') }}
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-right mt-2">
            <span>{{ $t('sended') }}</span>
            <b-link :to="{name:'auth-login'}">
              <span class="font-weight-bolder">&nbsp;{{ $t('login') }}</span>
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import AiotLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BCardTitle, BImg, BForm, BFormGroup, BFormInput, BButton, BAlert,
} from 'bootstrap-vue'
import { required, email } from '@validations'

export default {
  components: {
    AiotLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    ValidationProvider,
    ValidationObserver,
    BAlert,
  },
  data() {
    return {
      userEmail: '',
      sideImg: require('@/assets/images/pages/bg-dark-forget.png'),
      // validation
      required,
      email,
    }
  },
  computed: {
    imgUrl() {
      if (this.$store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/bg-dark-forget.png')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$xtoast.error('请联系管理员！')
        }
      })
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
</style>
